import React from 'react';
import SignMessage from './SignMessage';
import SignHalfTrains from './SignHalfTrains';
import { MessageRowSizes, SingleTrainRowSizes } from '../types';

const SignFullMessageTrains: React.FC = () => {
  return (
    <div className="signFullMessageTrains">
      <SignHalfTrains height={SingleTrainRowSizes.HALF_MARGIN} />
      <div style={{
        height: 20
      }} />
      <SignMessage filter='HALF' height={MessageRowSizes.HALF_MARGIN} />
    </div>
  )
};

export default SignFullMessageTrains;