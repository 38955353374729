import React from 'react';
import { routeBulletIconographer } from '../helpers/routeBulletIconographer';
import { AnyMessageProps, MessageRowSizes } from '../types';

export interface MessageProps {
  message: AnyMessageProps;
  height: MessageRowSizes;
}

const Message: React.FC<MessageProps> = ({ message, height }) => {
  if(!message) return null
  const textContainerStyles = message.type==='FULL' ? {padding: 40, paddingTop: 64} : {padding: 24, paddingTop: 48}
  const detailMessageClasses = message.type === 'FULL' ? 'details-full' : 'details-normal';

  
  return (
    <div className={`signMessage ${height}`}>
      <div className='gold-bar' />
      <div className='text-container' style={textContainerStyles} >
        {message.type==='FULL' 
          ? <h1 className="headline">
              {routeBulletIconographer(message.headline)}</h1> 
          : null}
        <p className={detailMessageClasses}>
          {routeBulletIconographer(message.details)}
        </p>
      </div>
    </div>
  );
};

export default Message;