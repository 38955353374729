import { useEffect } from 'react';
import { MetricName, SignMode } from '../types';
import { useCloudWatch } from '../contexts/CloudwatchMetricContext';
import { StandardUnit } from '@aws-sdk/client-cloudwatch';

export const useMetricModeChange = (mode: SignMode) => {
  const { addMetricRecord } = useCloudWatch();

  useEffect(() => {
    addMetricRecord({
      MetricName: MetricName.Mode,
      Value: mode.valueOf(),
      Unit: StandardUnit.None,
      Timestamp: new Date(),
    });
  }, [mode]);
};