import { useEffect, useRef } from 'react';
import Row from './row';
import { SignTrain, SingleTrainRowSizes } from '../types';

interface RowProps {
  animate: boolean;

  nextTrains: SignTrain[]|null;
  nextTrainIndex: number|null;
  nextForceArrow: boolean|null;

  prevTrains: SignTrain[]|null;
  prevTrainIndex: number|null;
  prevForceArrow: boolean|null;

  isToggled: boolean;
  height: SingleTrainRowSizes;

  onAnimationComplete: ()=>void;
}

const RowAnimator: React.FC<RowProps> = ({
  animate, 
  nextTrains, nextTrainIndex, nextForceArrow,
  prevTrains, prevTrainIndex, prevForceArrow,
  isToggled,
  height,
  onAnimationComplete 
}) => {
  
  const boxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const box = boxRef.current;

    if (!box) return;

    box.addEventListener('animationend', onAnimationComplete);

    // Cleanup the event listener
    return () => {
      box.addEventListener('animationend', onAnimationComplete);
    };
  }, [onAnimationComplete]);


  const { prevRow, nextRow, type } = createRows(
    nextTrains,
    nextTrainIndex,
    nextForceArrow,
    prevTrains,
    prevTrainIndex,
    prevForceArrow,
    isToggled,
    height
  )

  const finalType = animate ? type : RowAnimationTypes.DEFAULT

  switch (finalType) {
    case RowAnimationTypes.ROLL_OUT:
      return (
        <div ref={boxRef} className={`beacons-row-animate ${finalType}`}>
          {prevRow}
        </div>
      );
    case RowAnimationTypes.ROLL_IN:
      return (
        <div ref={boxRef} className={`beacons-row-animate ${finalType}`}>
          {nextRow}
        </div>
      );
    case RowAnimationTypes.CHA_CHING:
    case RowAnimationTypes.FRONT_ONLY:
    case RowAnimationTypes.BACK_ONLY:
      return (
        <div ref={boxRef} className={`beacons-row-animate ${finalType}`}>
          <div className="front">{prevRow}{nextRow}</div>
          <div className="back">{prevRow}{nextRow}</div>
        </div>
      );
    case RowAnimationTypes.DEFAULT:
    default:
      return nextRow
  }
}

export default RowAnimator;

enum RowAnimationTypes {
  ROLL_OUT='roll-out',
  ROLL_IN='roll-in',
  CHA_CHING='cha-ching',
  FRONT_ONLY='front-only',
  BACK_ONLY='back-only',
  DEFAULT='default'
}


const createRows = (
  nextTrains: SignTrain[]|null,
  nextTrainIndex: number|null,
  nextForceArrow: boolean|null,

  prevTrains: SignTrain[]|null,
  prevTrainIndex: number|null,
  prevForceArrow: boolean|null,

  isToggled: boolean,
  height: SingleTrainRowSizes
) => {
  // Create the next row
  const haveNextData = nextTrainIndex !== null && nextTrains !== null && nextForceArrow !== null && nextTrains.length > nextTrainIndex
  const nextTrain = haveNextData ? nextTrains[nextTrainIndex] : undefined
  const nextRow = <Row forceArrow={nextForceArrow ?? false} isToggled={isToggled} train={nextTrain} height={height} />;

  // Create the prev row
  const havePrevData = prevTrainIndex !== null && prevTrains !== null && prevForceArrow !== null && prevTrains.length > prevTrainIndex
  const prevTrain = havePrevData ? prevTrains[prevTrainIndex] : undefined
  const prevRow = <Row forceArrow={prevForceArrow ?? false} isToggled={isToggled} train={prevTrain} height={height} />

  if(prevTrain && !nextTrain){
    return {prevRow, nextRow: null, type:RowAnimationTypes.ROLL_OUT }
  }
  if(!prevTrain && nextTrain){
    return {prevRow: null, nextRow, type:RowAnimationTypes.ROLL_IN }
  }

  if(prevTrain){
    const isIndexSame = prevTrain?.index===nextTrain?.index
    const isBodySame = prevTrain?.interval===nextTrain?.interval && prevTrain?.route===nextTrain?.route 
      && prevTrain?.primary===nextTrain?.primary && prevTrain?.secondary===nextTrain?.secondary
      && prevForceArrow===nextForceArrow

    // Mostly the same, Enough matches to just update in place
    if(isBodySame && isIndexSame){
      return {prevRow: null, nextRow, type:RowAnimationTypes.DEFAULT }
    }

    // Index Different, Animate the index only
    if(isBodySame && !isIndexSame){
      return {prevRow, nextRow, type:RowAnimationTypes.FRONT_ONLY }
    }

    // Body Different, Animate the body only
    if(!isBodySame && isIndexSame){
      return {prevRow, nextRow, type:RowAnimationTypes.BACK_ONLY }
    }

    // All Different, Full Animation
    return { prevRow, nextRow, type:RowAnimationTypes.CHA_CHING };
  }

  return {prevRow: null, nextRow, type:RowAnimationTypes.DEFAULT }
}
