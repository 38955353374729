import React, { useCallback, useEffect, useState } from 'react';
import { SignTrain, SingleTrainRowSizes, Train } from '../types';
import { useData } from '../contexts/DataContext';
import { makeSignTrain, calcIntervalEqual, isSafeToUpdate, shouldForceArrow } from '../helpers/train';
import RowAnimator from './RowAnimator';

interface SignData {
  trains: SignTrain[],
  trainIndex: number,
  forceArrow: boolean,
  animate: boolean,
  previousTrains: SignTrain[]|null,
  previousTrainIndex: number|null,
  previousForceArrow: boolean|null
}

interface SignHalfTrainsProps {
  height: SingleTrainRowSizes;
}

const SignHalfTrains: React.FC<SignHalfTrainsProps> = ({ height }) => {
  const { trains: heliumTrains } = useData();
  const [signData, setSignData] = useState<SignData>(breakCycle(heliumTrains.current))

  //advance function
  const advance = useCallback(()=>{
    setSignData(prev => {
      const newHeliumTrainList = heliumTrains.current

      // No trains case
      if(!newHeliumTrainList || newHeliumTrainList.length===0){
        return breakCycle(newHeliumTrainList)
      }

      const newTrainList = makeSignTrain(newHeliumTrainList)
      const newArriving = newTrainList.filter(t=>t.isArriving)

      const oldTrainList = prev.trains
      const oldArriving = oldTrainList.filter(t=>t.isArriving)

      //Animations
      const advanceAnimationState = {
        animate: true,
        previousTrains: oldTrainList,
        previousTrainIndex: prev.trainIndex,
        previousForceArrow: prev.forceArrow
      }

      // Single train case
      if(newTrainList.length===1){
        //lock the single row/no advancing
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      // Single arriving train case
      if(newArriving.length===1){
        //lock it
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      // If transitioning between different trains arriving
      const arrivingTransition = oldArriving.length>0 || newArriving.length>0
      const areIntervalsEqual = calcIntervalEqual(oldArriving, newArriving)
      if(arrivingTransition && !areIntervalsEqual){
        // then break the cycle
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      //Advance Logic

      //the list of trains that will loop
      let trains = oldTrainList
      let trainCycle = oldTrainList

      // Soft Update when new data is similar to old data, show latest
      const safeToUpdate = isSafeToUpdate(oldTrainList, newTrainList, prev.trainIndex)
      if(safeToUpdate){
        trains = newTrainList
        trainCycle = newTrainList
      }

      //has arriving, show latest
      if(newArriving.length>0){
        trains = newTrainList
        trainCycle = newArriving
      }

      //Find next index
      const prevSubIndex = trainCycle.findIndex(a=>a.index===prev.trainIndex);
      if(prevSubIndex===-1){
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }
      const nextSubIndex = prevSubIndex + 1
      const isAtEndOfCycle = nextSubIndex >= trainCycle.length
      if(isAtEndOfCycle){
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      return {
        trains,
        trainIndex: trainCycle[nextSubIndex].index,
        forceArrow: prev.forceArrow,
        ...advanceAnimationState
      }

    })
  },[])

  // Start advancing when the component mounts
  useEffect(() => {
    const rotationTime = import.meta.env.VITE_SCREEN_ROTATION || 1000;
    const timer = setInterval(advance, rotationTime);
    return () => clearInterval(timer)
  }, [advance]);

  const train = signData.trains[signData.trainIndex]

  // Arriving style toggler
  const [isToggled, setIsToggled] = useState<boolean>(false);
  useEffect(() => {
    if(!train?.isArriving) return
    const timer = setInterval(() => setIsToggled(prevState => !prevState), 1000);
    return () => clearInterval(timer)
  }, [train?.isArriving]);

  const handleAnimationEnd = () => {
    setSignData(prev => {
      return {
        ...prev,
        animate: false
      }
    })
  };


  return (
    <div className={`signHalfTrains ${height}`}>
      <RowAnimator 
        animate={signData.animate}
        nextTrains={signData.trains} nextTrainIndex={signData.trainIndex} nextForceArrow={signData.forceArrow}
        prevTrains={signData.previousTrains} prevTrainIndex={signData.previousTrainIndex} prevForceArrow={signData.previousForceArrow}
        isToggled={isToggled}
        height={height}
        onAnimationComplete={handleAnimationEnd} />
    </div>
  )
};

export default SignHalfTrains;

type SignDataPreviousProps = Pick<SignData, 'animate' | 'previousTrains' | 'previousTrainIndex' | 'previousForceArrow'>;

const breakCycle = (heliumTrains: Train[], previous?:SignDataPreviousProps)=>{
  const trains = makeSignTrain(heliumTrains)
  const newArriving = trains.filter(t=>t.isArriving)

  //no arriving
  let trainCycle = trains
  let trainIndex = trains.length>0 ? 0 : -1

  //has arriving
  if(newArriving.length>0){
    trainCycle = newArriving
    trainIndex = newArriving[0].index
  }

  const forceArrow = shouldForceArrow(trainCycle)

  const overrides = previous!==undefined 
    ? previous 
    : {
      animate: true,
      previousTrains: null,
      previousTrainIndex: null,
      previousForceArrow: null
    }
  
  return {
    trains,
    trainIndex,
    forceArrow,
    ...overrides
  }
}