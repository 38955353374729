import { useEffect } from 'react';

function VersionChecker() {
  const pollInterval = parseInt(import.meta.env.VITE_COMMIT_REF_POLL_INTERVAL, 10) || 600000;  // Default to 10 minutes
  const environment = import.meta.env.VITE_ENVIRONMENT;
  const currentCommitRef = import.meta.env.VITE_COMMIT_REF || 'local';

  useEffect(() => {
    // Skip the check if the environment is local
    if (environment === 'local') {
      console.log('Version check is disabled in the local environment.');
      return;
    }
    if (currentCommitRef === 'local') {
      console.log('Version check is disabled, commitRef is invalid.');
      return;
    }

    // Function to fetch the current COMMIT_REF
    const fetchCommitRef = async () => {
      try {
        const response = await fetch('/version.json');
        const data = await response.json();
        return data.commitRef;
      } catch (error) {
        console.error('Failed to fetch version.json:', error);
        return null;  // In case of error, return null to avoid unintended reloads
      }
    };

    const intervalId = setInterval(async () => {
      const newCommitRef = await fetchCommitRef();
      if (newCommitRef && newCommitRef !== currentCommitRef) {
        console.log('New COMMIT_REF detected, reloading the page.', {'from':currentCommitRef, 'to':newCommitRef});
        window.location.reload();
      }
    }, pollInterval);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [pollInterval, environment, currentCommitRef]);

  return null;  // This component does not render anything
}

export default VersionChecker;
