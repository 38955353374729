
const key = 'BEACONS_FINGERPRINT'

// Generate a random UUID (version 4)
const generateUUID = (): string => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
})


export const getClientFingerprint = (): string => {
    // Check if the UUID is already in local storage
    const storedUUID = localStorage.getItem(key);
    
    if (storedUUID) {
        return storedUUID;
    }

    // Generate a new UUID
    const uuid = generateUUID();

    // Store the new UUID in local storage
    localStorage.setItem(key, uuid);

    return uuid;
}