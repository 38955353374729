import { SignTrain, Train } from "../types";

export const makeSignTrain=(trainList: Train[]):SignTrain[]=>{
    return trainList.map((train, index) =>({
        ...train,
        index,
        isArriving: calcIsArriving(train.stop_status),
        etaMinutes: calcETAMinutes(train.est_time)
    }))
}

export const calcETAMinutes=(est_time: number): number => {
    if(!est_time) return 0
    const currentTime = Math.floor(Date.now() / 1000);
    return Math.max(1, Math.floor((est_time - currentTime) / 60));
}
export const calcIsArriving=(stop_status: string): boolean => 
    ['AT_STATION', 'ARRIVING'].includes(stop_status)

export const shouldForceArrow=(trainList: SignTrain[]): boolean =>
    trainList.some(train => typeof train.arrow === 'number')

export const calcIntervalEqual=(previouslyArriving:SignTrain[],currentlyArriving:SignTrain[])=>
    currentlyArriving.length === previouslyArriving.length &&
    currentlyArriving.every((current, index) => current.interval === previouslyArriving[index].interval);



export function isSafeToUpdate(currentList: SignTrain[], newList: SignTrain[], currentIndex: number): boolean {
    // possible for index to be negative
    if(currentIndex<0)
        return false;

    // Check if the currentIndex is out of bounds for either list
    if (currentIndex >= currentList.length || currentIndex >= newList.length) {
        return false;
    }

    // Check if all train intervals from index 0 to currentIndex are equal
    if (!currentList.slice(0, currentIndex + 1).every((train, i) => train.interval === newList[i].interval)) {
        return false;
      }

    // Check if the current index ETA in currentList is less than the next index ETA in newList
    const currentEta = currentList[currentIndex].est_time;
    const nextEtaInNewList = newList[currentIndex + 1]?.est_time;

    if (nextEtaInNewList !== undefined && currentEta >= nextEtaInNewList) {
        return false;
    }

    // If both checks pass, it is safe to update
    return true;
}
