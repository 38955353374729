import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { SignHeight } from '../types';
import { isSolari } from '../helpers/isSolari';

export const useValidateHeight = (height: SignHeight, id: string | undefined) => {
  useEffect(() => {
    if (height === SignHeight.Unknown) return;

    const windowHeight = window.innerHeight;
    if (isSolari() && windowHeight !== height.valueOf()) {
      Sentry.captureException(
        new Error(
          `My Height is set incorrectly! {'node':${id}, 'windowHeight':${windowHeight}, 'nodeHeight':${height.valueOf()}} `
        )
      );
    }
  }, [height, id]);
};