import { AnyMessageProps, SignHeight, SignMode, Train } from "../types";

export const determineSignMode = (
  messages: AnyMessageProps[],
  trains: Train[],
  height: SignHeight
): SignMode => {
  if(height===SignHeight.FULL){
    if (messages.filter(m=>m.type==='FULL').length > 0) 
      return SignMode.FullMessage;
    if (messages.filter(m=>m.type==='HALF').length > 0 && trains.length > 0) 
      return  SignMode.FullMessageTrains
    if (trains.length > 0) {
      return SignMode.FullTrains
    }
  }

  if(height===SignHeight.HALF){
    if (messages.filter(m=>m.type==='HALF').length > 0) 
      return SignMode.HalfMessage;
    if (trains.length > 0) {
      return SignMode.HalfTrains
    }
  }


  return SignMode.None;
};
