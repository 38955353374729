import { ReactNode } from "react";
import SignNoRealtime from "../components/SignNoRealtime";
import SignLoading from "../components/SignLoading";

  
  interface Demo {
    title: string;
    url?: string;
    children?: ReactNode;
    width: number;
    height: number;
  }

const Demo: React.FC = () => {
  const demos = [
    {
      title: 'Full Screen - Normal',
      url: '/node/1456',
      width: 1920,
      height: 480
    },
    {
      title: 'Full Screen - Half Message',
      url: '/node/3396',
      width: 1920,
      height: 480
    },
    {
      title: 'Full Screen - Full Message',
      url: '/node/12611',
      width: 1920,
      height: 480
    },
    {
      title: 'Half Screen - Normal',
      url: '/node/8736',
      width: 1920,
      height: 240
    },
    {
      title: 'Half Screen - Half Message',
      url: '/node/1736',
      width: 1920,
      height: 240
    },
    {
      title: 'JFK Icon',
      url: '/node/13376',
      width: 1920,
      height: 480
    },
    {
      title: 'Full Screen - Loading',
      children: <SignLoading/>,
      width: 1920,
      height: 480
    },
    {
      title: 'Full Screen - No Realtime',
      children: <SignNoRealtime/>,
      width: 1920,
      height: 480
    },
    {
      title: 'Half Screen - Loading',
      children: <SignLoading/>,
      width: 1920,
      height: 240
    },
    {
      title: 'Half Screen - No Realtime',
      children: <SignNoRealtime/>,
      width: 1920,
      height: 240
    }
  ]
return (
    <div className="grid gap-4 p-4 grid-cols-[repeat(auto-fit,minmax(500px,1fr))]">
      {demos.map((demo, index) => (
        <div
          key={index}
          className="h-full w-full flex flex-col items-center p-4 rounded-lg shadow-md overflow-hidden"
          style={{
            backgroundColor: 'rgb(31, 41, 55)'
          }}
        >
          <h2 className="text-xl bold text-white mb-1">{demo.title}</h2>
          {demo.url ? <a
            href={demo.url}
            className="text-sm underline mb-3"
            style={{
              color: 'rgb(147, 197, 253)'
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`Node: ${demo.url.split('/').pop()}`}
          </a> : null }
          <div
            className="overflow-hidden relative"
            style={{ width: demo.width * 0.25, height: demo.height * 0.25 }}
          >
            {demo.url ?
              <iframe
                src={demo.url}
                width={demo.width}
                height={demo.height}
                style={{ transform: 'scale(0.25)', transformOrigin: 'top left' }}
                className="border-none"
                title={demo.title}
              ></iframe>
              : (
                <div
                  style={{ height:demo.height,width:demo.width,  transform: 'scale(0.25)', transformOrigin: 'top left' }}>
                  {/* The below line needs to match the wrapper in sign.tsx */}
                  <div className='sign'>{demo.children}</div>
                </div>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Demo;