import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { CloudWatchClient, PutMetricDataCommand, StandardUnit } from '@aws-sdk/client-cloudwatch';
import { useParams } from 'react-router-dom';
import { isSolari } from '../helpers/isSolari';
import { getClientFingerprint } from '../helpers/clientFingerprint';

interface MetricRecord {
  MetricName: string;
  Unit: StandardUnit;
  Value: number;
  Timestamp: Date;
}

interface CloudWatchContextProps {
  metricRecords: MetricRecord[];
  addMetricRecord: (record: MetricRecord) => void;
  setMRN: React.Dispatch<React.SetStateAction<number | undefined>>
}

const CloudWatchContext = createContext<CloudWatchContextProps | undefined>(undefined);

// Initialize CloudWatch client
const cloudWatchClient = new CloudWatchClient({
  region: import.meta.env.VITE_AWS_REGION,
  credentials: {
    accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  }
});

interface CloudWatchProviderProps {
  children: React.ReactNode;
}

const AWS_METRIC_DATA_LIMIT = 1000
const AWS_METRIC_VALUES_LIMIT = 150

export const CloudWatchProvider: React.FC<CloudWatchProviderProps> = ({ children }) => {
  const { id } = useParams();

  const [metricRecords, setMetricRecords] = useState<MetricRecord[]>([]);
  const [mrn, setMRN] = useState<number>();

  // Create a ref to hold the latest metricRecords, this is needed so that timers do not reset when records are added.
  const metricRecordsRef = useRef<MetricRecord[]>([]); 
  // Update the ref whenever metricRecords changes
  useEffect(() => {
    metricRecordsRef.current = metricRecords;
  }, [metricRecords]);
  
  const addMetricRecord = (record: MetricRecord) => {
    setMetricRecords((prevRecords) => [...prevRecords, record]);
  };

  const sendMetricsToCloudWatch = useCallback(async () => {
    const records = metricRecordsRef.current; // Use the latest metricRecords from the ref

    if (records.length === 0) return;

    const now = new Date();
    const roundedEpochTime = Math.floor(now.getTime() / 60000) * 60000;

    // Filter records older than 1 minute from the floored current time
    const eligibleRecords = records.filter(record => record.Timestamp.getTime() <= roundedEpochTime)
      .slice(0,AWS_METRIC_DATA_LIMIT)

    if (eligibleRecords.length === 0) return;

    // Group records by minute and metric name
    const groupedMetrics: { [key: string]: { values: number[]; counts: number[]; metrics: MetricRecord[] } } = {};
    eligibleRecords.forEach(record => {

      const formattedToTheMinute = Math.floor(record.Timestamp.getTime() / 60000) * 60000;
      const key = `${record.MetricName}-${formattedToTheMinute}`;

      if (!groupedMetrics[key]) {
        groupedMetrics[key] = { values: [], counts: [], metrics:[] };
      }

      const valueIndex = groupedMetrics[key].values.indexOf(record.Value);

      // skip records if our buffer is too high
      if (groupedMetrics[key].values.length >= AWS_METRIC_VALUES_LIMIT) return;

      if (valueIndex === -1) {
        // If the value is unique, add it and set its count to 1
        groupedMetrics[key].values.push(record.Value);
        groupedMetrics[key].counts.push(1);
        groupedMetrics[key].metrics.push(record);
      } else {
        // If the value already exists, increment its count
        groupedMetrics[key].counts[valueIndex]++;
      }
    });

    // Prepare the params for PutMetricDataCommand
    const params = {
      MetricData: Object.keys(groupedMetrics).map(key => {
        const metricName = key.split('-')[0];
        const formattedToTheMinute = new Date(Math.floor(groupedMetrics[key].metrics[0].Timestamp.getTime() / 60000) * 60000);
        const metricData = groupedMetrics[key];

        return {
          MetricName: metricName,
          Timestamp: formattedToTheMinute,
          Unit: metricData.metrics[0].Unit,
          Values: metricData.values,
          Counts: metricData.counts,
          Dimensions: [
            { Name: 'Environment', Value: import.meta.env.VITE_ENVIRONMENT },
            ...(id ? [{ Name: 'NodeId', Value: id }] : []),
            ...(mrn ? [{ Name: 'MRN', Value: mrn }] : []),
            { Name: 'Version', Value: import.meta.env.VITE_COMMIT_REF || 'local' },
            { Name: 'IsSolari', Value: isSolari()?'Yes':'No' },
            { Name: 'SignFingerprint', Value: getClientFingerprint() }
          ]
        };
      }),
      Namespace: import.meta.env.VITE_AWS_CLOUDWATCH_METRICS_NAMESPACE
    };

    try {
      console.log('Sending records to CloudWatch',params,metricRecordsRef);
      await cloudWatchClient.send(new PutMetricDataCommand(params));
      // Remove the eligible records from the state
      setMetricRecords(prevRecords => prevRecords.filter(record => !eligibleRecords.includes(record)));
    } catch (error) {
      console.error('Error sending metrics to CloudWatch:', error);
    }
  }, [id,mrn]);

  useEffect(() => {
    const interval = setInterval(
      sendMetricsToCloudWatch, 
      import.meta.env.VITE_AWS_CLOUDWATCH_METRICS_INTERVAL
    );
    return () => clearInterval(interval);
  }, [sendMetricsToCloudWatch]);

  return (
    <CloudWatchContext.Provider value={{ metricRecords, addMetricRecord, setMRN }}>
      {children}
    </CloudWatchContext.Provider>
  );
};

export const useCloudWatch = () => {
  const context = useContext(CloudWatchContext);
  if (!context) {
      throw new Error('useCloudWatch must be used within a CloudWatchProvider');
  }
  return context;
};