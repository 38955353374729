// import { CloudWatchLogsClient, CreateLogStreamCommand, PutLogEventsCommand } from '@aws-sdk/client-cloudwatch-logs';




// const cloudWatchLogsClient = new CloudWatchLogsClient({
//     region: import.meta.env.VITE_AWS_REGION,
//     credentials: {
//       accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
//       secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
//     }
// });

// const logGroupName = import.meta.env.VITE_CLOUDWATCH_LOG_GROUP_NAME;
// const logStreamName = import.meta.env.VITE_CLOUDWATCH_LOG_STREAM_NAME;

// Ensure that a log stream is created in CloudWatch
// const createLogStream = async () => {
//     try {
//         const params = {
//             logGroupName,
//             logStreamName,
//         }
//         await cloudWatchLogsClient.send(new CreateLogStreamCommand(params));
//     } catch (error) {
//         console.error('Failed to create log stream:', error);
//     }
// };

// Initialize the log stream
// createLogStream();

export const logToCloudWatch = async (level: 'INFO' | 'DEBUG' | 'WARN' | 'ERROR', message: string, additionalData: Record<string, any> = {}) => {
    const logMessage = {
        timestamp: new Date().toISOString(),
        level,
        message,
        ...additionalData,
    };
    console.log(logMessage)

    // try {
    //     // Dave: I read somewhere that the sequenceToken is no longer needed
    //     // Get the next sequence token (necessary for CloudWatch Logs)
    //     // const logStreamData = await cloudWatchLogs.describeLogStreams({
    //     //     logGroupName,
    //     //     logStreamNamePrefix: logStreamName,
    //     // }).promise();

    //     // const sequenceToken = logStreamData.logStreams?.[0]?.uploadSequenceToken;

    //     // Send the log to CloudWatch

    //     const params = {
    //         logGroupName,
    //         logStreamName,
    //         logEvents: [
    //             {
    //                 message: JSON.stringify(logMessage),
    //                 timestamp: Date.now(),
    //             },
    //         ],
    //         // sequenceToken,
    //     }
    //     await cloudWatchLogsClient.send(new PutLogEventsCommand(params));

    // } catch (error) {
    //     console.error('Failed to log to CloudWatch:', error);
    // }
};
