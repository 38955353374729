import { FullMessageProps, HalfMessageProps, MessageRowSizes } from '../types';
import Message from '../components/message';
import { useEffect, useState } from 'react';

const MessageWrapper = () => {

    const [message,setMessage] = useState<HalfMessageProps|FullMessageProps>({
        headline: "",
        details: "",
        type:"FULL"
    });
    
    useEffect(() => {
      const handler = (event: MessageEvent) => {
        if (import.meta.env.VITE_PREVIEW_ALLOWED !== event.origin) {
          // Ignore messages from unknown origins
          return;
        }
  
        const { type, payload } = event.data;
  
        if (type === 'SET_DATA') {
          setMessage(payload)
        }
      };
  
      window.addEventListener("message", handler)
  
      // clean up
      return () => window.removeEventListener("message", handler)
    }, [])
    
    const messageHeight = message.type==='FULL' ? MessageRowSizes.FULL : MessageRowSizes.HALF

    return <Message message={message} height={messageHeight} />;
};

export default MessageWrapper;