import React from 'react';

const SignNoRealtime: React.FC = () => {
  
  return (
    <div className="signNoRealtime">
        <div>
            Realtime arrival info is unavailable
        </div>
        <div className="font-weight-normal">
            Check <span className="font-url">mta.info</span> for service information
        </div>
    </div>
  );
};

export default SignNoRealtime;