import React, { useCallback, useEffect, useState } from 'react';
import Message from './message';
import { AnyMessageProps, MessageRowSizes } from '../types';
import { useData } from '../contexts/DataContext';

interface SignData {
  messages: AnyMessageProps[],
  messageIndex: number,
}

interface SignMessageProps {
  filter: AnyMessageProps['type'];
  height: MessageRowSizes;
}

const SignMessage: React.FC<SignMessageProps> = ({ filter, height }) => {
  const { messages } = useData()
  const filteredMessages = filterMessages(messages.current,filter);

  const [signData, setSignData] = useState<SignData>({
    messages:filteredMessages,
    messageIndex: filteredMessages.length>0 ? 0 : -1
  })

  //rotate function
  const advance = useCallback(()=>{      
    setSignData(prev => {
      const filteredMessages = filterMessages(messages.current,filter);

      //If the sign has completely cycled through it's messages
      const isAtEndOfCycle = prev.messageIndex + 1 >= prev.messages.length

      //update with current data and reset cycle
      if(isAtEndOfCycle)
        return{
          messages:filteredMessages,
          messageIndex:filteredMessages.length>0 ? 0 : -1
        }
      
      return {
        messages: prev.messages,
        messageIndex: isAtEndOfCycle ? 0 : prev.messageIndex + 1
      }
    })
  },[filter])

  // Start rotating when the component mounts
  useEffect(() => {
    const rotationTime = import.meta.env.VITE_SCREEN_ROTATION || 1000;
    const timer = setInterval(advance, rotationTime);

    // Cleanup function to stop the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [advance]);

  const message = signData.messages[signData.messageIndex]
  return <Message message={message} height={height}/>
};

export default SignMessage;



const filterMessages = (messages: AnyMessageProps[],filter: AnyMessageProps['type'])=>{
  switch(filter){
    case 'FULL':
      const onlyOneFullMessage = messages.find(m => m.type === filter)
      return onlyOneFullMessage ? [onlyOneFullMessage] : []
    case 'HALF':
      return messages.filter(m => m.type === filter)
  }
  
  
}