import * as Sentry from '@sentry/browser';

const isEnabled = import.meta.env.VITE_API_SENTRY_ENABLED === 'true';

// If sentry is enabled, we send the events to sentry.
// If not, then we punt them into the bin, but we can still see it tried.
if (isEnabled) {
  Sentry.init({
    dsn: import.meta.env.VITE_API_SENTRY_DSN,
    debug: true,
    environment: import.meta.env.VITE_ENVIRONMENT
  });
} else {
  Sentry.init({
    dsn: 'https://examplePublicKey@o0.ingest.sentry.io/0',
    debug: true,
    beforeSend(event) {
      console.log('Sentry event:', event);
      return null;
    }
  });
}
