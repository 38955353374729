import React, { useCallback, useEffect, useState } from 'react';
import { SingleTrainRowSizes, SignTrain, Train } from '../types';
import { useData } from '../contexts/DataContext';
import { makeSignTrain, calcIntervalEqual, isSafeToUpdate, shouldForceArrow } from '../helpers/train';
import RowAnimator from './RowAnimator';

interface SignData {
  trains: SignTrain[],
  trainFirstIndex: number,
  trainSecondIndex: number,
  forceArrow: boolean,
  animate: boolean,
  previousTrains: SignTrain[]|null,
  previousTrainFirstIndex: number|null,
  previousTrainSecondIndex: number|null,
  previousForceArrow: boolean|null
}

const SignFullTrains: React.FC = () => {
  const { trains: heliumTrains } = useData();
  const [signData, setSignData] = useState<SignData>(breakCycle(heliumTrains.current))

  //advance function
  const advance = useCallback(()=>{
    setSignData(prev => {
      const newHeliumTrainList = heliumTrains.current;

      // No trains case
      if (!newHeliumTrainList || newHeliumTrainList.length===0) {
        return breakCycle(newHeliumTrainList)
      }

      const newTrainList = makeSignTrain(newHeliumTrainList)
      const newArriving = newTrainList.filter(t=>t.isArriving)

      const oldTrainList = prev.trains
      const oldArriving = oldTrainList.filter(t=>t.isArriving)

      //Animations
      const advanceAnimationState = {
        animate: true,
        previousTrains: oldTrainList,
        previousTrainFirstIndex: prev.trainFirstIndex,
        previousTrainSecondIndex: prev.trainSecondIndex,
        previousForceArrow: prev.forceArrow
      }

      // Single train case
      if (newHeliumTrainList.length===1) {
        //lock the single row/no advancing
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      // Two train case
      if (newHeliumTrainList.length === 2 ) {
        //lock both rows/no advancing
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      // Cases: *->2
      // Two arriving trains case
      if(newArriving.length===2){
        //lock both rows/no advancing
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      // If transitioning between different trains arriving
      // Cases: n->!n
      // Special Cases: n->n where the count is equal but the train intervals aren't
      const arrivingTransition = oldArriving.length>0 || newArriving.length>0
      const areIntervalsEqual = calcIntervalEqual(oldArriving, newArriving)
      if(arrivingTransition && !areIntervalsEqual){
        // then break the cycle
        return breakCycle(heliumTrains.current,advanceAnimationState)
      }

      //Advance Logic

      //the list of trains that will loop
      let trains = oldTrainList
      let trainCycle = oldTrainList
      let trainFirstIndex = oldTrainList.length>0 ? 0 : -1

      // Soft Update when new data is similar to old data
      const safeToUpdate = isSafeToUpdate(oldTrainList, newTrainList, prev.trainSecondIndex)
      if(safeToUpdate){
        trains = newTrainList
        trainCycle = newTrainList
        trainFirstIndex = newTrainList.length>0 ? 0 : -1
      }

      //more than 1 arriving, show latest
      if(newArriving.length>1){
        trains = newTrainList
        trainCycle = newArriving
        trainFirstIndex = newArriving.length>0 ? newArriving[0].index : -1
      }
      //1 arriving
      else if(newArriving.length===1){
        //if we didn't soft update, then hard update
        if(!safeToUpdate){
          return breakCycle(newHeliumTrainList,advanceAnimationState)
        }
        trains = newTrainList
        trainCycle = trains.filter(t=>!t.isArriving)
        trainFirstIndex = newArriving.length>0 ? newArriving[0].index : -1
      }

      //Find next index
      const prevSubIndex = trainCycle.findIndex(a=>a.index===prev.trainSecondIndex);
      if(prevSubIndex===-1){
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }
      const nextSubIndex = prevSubIndex + 1
      const isAtEndOfCycle = nextSubIndex >= trainCycle.length
      if(isAtEndOfCycle){
        return breakCycle(newHeliumTrainList,advanceAnimationState)
      }

      return {
        trains,
        trainFirstIndex,
        trainSecondIndex: trainCycle[nextSubIndex].index,
        forceArrow: prev.forceArrow,
        ...advanceAnimationState
      }

    })
  },[])

  // Start advancing when the component mounts
  useEffect(() => {
    const rotationTime = import.meta.env.VITE_SCREEN_ROTATION || 1000;
    const timer = setInterval(advance, rotationTime);
    return () => clearInterval(timer)
  }, [advance]);

  const trainOne = signData.trains[signData.trainFirstIndex]
  const trainTwo = signData.trains[signData.trainSecondIndex]

  // Arriving style toggler
  const [isToggled, setIsToggled] = useState<boolean>(false);
  useEffect(() => {
    if(!trainOne?.isArriving && !trainTwo?.isArriving) return
    const timer = setInterval(() => setIsToggled(prevState => !prevState), 1000);
    return () => clearInterval(timer)
  }, [trainOne?.isArriving,trainTwo?.isArriving]);

  const handleAnimationEnd = () => {
    setSignData(prev => {
      return {
        ...prev,
        animate: false
      }
    })
  };

  return (
    <div className="signFullTrains">
      <div className={`firstRow ${SingleTrainRowSizes.HALF_MARGIN}`}>
        <RowAnimator 
          animate={signData.animate}
          nextTrains={signData.trains} nextTrainIndex={signData.trainFirstIndex} nextForceArrow={signData.forceArrow}
          prevTrains={signData.previousTrains} prevTrainIndex={signData.previousTrainFirstIndex} prevForceArrow={signData.previousForceArrow}
          isToggled={isToggled}
          height={SingleTrainRowSizes.HALF_MARGIN}
          onAnimationComplete={handleAnimationEnd} />
      </div>
      <div style={{
        height: 20
      }} />
      <div className={`secondRow ${SingleTrainRowSizes.HALF_MARGIN}`}>
        <RowAnimator 
          animate={signData.animate}
          nextTrains={signData.trains} nextTrainIndex={signData.trainSecondIndex} nextForceArrow={signData.forceArrow}
          prevTrains={signData.previousTrains} prevTrainIndex={signData.previousTrainSecondIndex} prevForceArrow={signData.previousForceArrow}
          isToggled={isToggled}
          height={SingleTrainRowSizes.HALF_MARGIN}
          onAnimationComplete={handleAnimationEnd} />
     </div>
    </div>
  )
};

export default SignFullTrains;

type SignDataPreviousProps = Pick<SignData, 'animate' | 'previousTrains' | 'previousTrainFirstIndex' | 'previousTrainSecondIndex' | 'previousForceArrow'>;

const breakCycle = (heliumTrains: Train[], previous?:SignDataPreviousProps)=>{
  const trains = makeSignTrain(heliumTrains)
  const newArriving = trains.filter(t=>t.isArriving)

  //if no arriving
  let trainCycle = trains
  let trainFirstIndex = trains.length>0 ? 0 : -1
  let trainSecondIndex = trains.length>1 ? 1 : -1

  //2 or more arriving
  if(newArriving.length>1){
    trainCycle=newArriving
    trainFirstIndex=newArriving[0].index
    trainSecondIndex=newArriving[1].index
  }
  //1 arriving
  else if(newArriving.length===1 && trains.length>1){
    //trainCycle = trains //already set above
    trainFirstIndex=newArriving[0].index
    trainSecondIndex=newArriving[0].index === 0 ? 1 : 0
  }

  const forceArrow = shouldForceArrow(trainCycle)

  const overrides = previous!==undefined 
    ? previous 
    : {
      animate: true,
      previousTrains: null,
      previousTrainFirstIndex: null,
      previousTrainSecondIndex: null,
      previousForceArrow: null
    }

  return {
    trains,
    trainFirstIndex,
    trainSecondIndex,
    forceArrow,
    ...overrides
  }

}