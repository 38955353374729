import { SignTrain, SingleTrainRowSizes } from '../types';

import Arrow from './arrow';
import { Icon } from './icon';
import { routeBulletIconographer } from '../helpers/routeBulletIconographer';

interface RowProps {
  train: SignTrain|undefined;
  forceArrow: boolean;
  isToggled: boolean;
  height: SingleTrainRowSizes;
}

const Row: React.FC<RowProps> = ({ train, forceArrow, isToggled, height }) => {
  if(!train) return <div className={`signTrain ${height}`} />

  
  const { index, arrow, route, primary, secondary, delayed, etaMinutes, isArriving } = train;
  const rowNumber = index+1

  const trainClasses = 
    isToggled && isArriving ? 'isToggled isArriving' :
    isArriving ? 'isArriving' : '';

  const etaText = isArriving ? '0' : delayed ? '-' : (etaMinutes?.toString() ?? '');
  const etaTextDesc = isArriving ? 'MIN' : delayed ? 'DELAY' : 'MIN';

  return (
    <div className={`signTrain ${height}`}>
      <div className="indexContainer">
        <div className='indexText'>
          {rowNumber}
        </div>
      </div>
      <div style={{ width: 40 }} />
      <div className={`trainContainer ${trainClasses}`}> 
        {forceArrow && (
          arrow !== undefined && arrow !== null && typeof train.arrow === 'number' ? (
            <>
              <Arrow rotation={arrow} color={isToggled && isArriving ? 'black' : 'white'} />
              <div style={{ width: 30 }} />
            </>
          ) : <div style={{ height: 100, width: 130 }} />
        )}

        <div className='trainIdentificationContainer'>
          {route ? (
            <Icon line={route} />
          ) : <div style={{ height: 170, width: 170 }} />
          }
          <div style={{ width: 20 }} />

          <div className='descriptorContainer'>
            <div className={`${forceArrow ? 'forceArrow' : ''} primaryText`}>
              {primary ? routeBulletIconographer(primary) : ''}
            </div>
            <div style={{ height: 30 }} />
            <div className={`${forceArrow ? 'forceArrow' : ''} secondaryText`}>
              {secondary ? routeBulletIconographer(secondary) : ''}
            </div>
          </div>
        </div>

        <div style={{ width: 30 }} />

        <div className="etaContainer">
          <div className="etaText">
            {etaText}
          </div>
          <div style={{ height: 15 }} />
          <div className='etaDescriptionText'>
            {etaTextDesc}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Row;