
import { useEffect } from 'react';
import { MetricName } from '../types';
import { useCloudWatch } from '../contexts/CloudwatchMetricContext';
import { StandardUnit } from '@aws-sdk/client-cloudwatch';

export const useMetricBrowserStart = () => {
  const { addMetricRecord } = useCloudWatch();

  useEffect(() => {
    const commitRef = import.meta.env.VITE_COMMIT_REF || 'local';
    console.log('Started with version:',commitRef)
    addMetricRecord({
      MetricName: MetricName.BrowserStart,
      Value: 1,
      Unit: StandardUnit.Count,
      Timestamp: new Date(),
    });
  }, []);
};