

export const runWithInterval = (callback:()=>void, interval:number) => {
    let timerId:number;
  
    const executeCallback = async () => {
        try {
            await callback();  // Ensure the callback completes before setting the next timeout
        } catch (error) {
            console.error('Dave, does this ever occur? An error occurred:', error);
            throw error;  // Re-throw the error so it can bubble up
        } finally {
            timerId = window.setTimeout(executeCallback, interval);  // Schedule the next execution
        }
    };
  
    timerId = window.setTimeout(executeCallback, interval);  // Schedule the next execution
  
    return () => clearTimeout(timerId); // Return a function to clear the timeout
}