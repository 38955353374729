import type { SVGProps } from "react"

interface ArrowProps extends SVGProps<SVGSVGElement> {
  rotation: number;
  color: string;
}

const Arrow = ({ rotation, color }: ArrowProps) => {
  return  (
    <svg className="arrow" width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" >
      <path
        fill={color}
        transform={`rotate(${rotation+90} 50 50)`}
        d="M7.62939e-05 49.3446L48.5501 98.5227L64.2355 82.7423L42.4917 60.8668H99.717V37.9059H42.4917L64.2355 16.0304L48.5501 0.25L7.62939e-05 49.3446Z" />
    </svg>
  )
}

export default Arrow