import "./helpers/instrument";
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorBoundary from "./components/errorBoundary";
import Sign from './app/Sign'
import './index.css';
import MessageWrapper from "./app/MessageWrapper";
import './shared/fonts.css'
import { CloudWatchProvider } from "./contexts/CloudwatchMetricContext";
import { DataProvider } from "./contexts/DataContext";
import SignConfigurator from "./app/SignConfigurator";
import Demo from "./app/Demo";
import VersionChecker from "./components/VersionChecker";

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="/node/:id" element={
            <CloudWatchProvider>
              <DataProvider>
                <VersionChecker />
                <Sign />
              </DataProvider>
            </CloudWatchProvider>
          } />
          <Route path="/messagePreview" element={<MessageWrapper />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/tester" element={
            <CloudWatchProvider>
              <DataProvider offline={true}>
                <SignConfigurator>
                  <Sign />
                </SignConfigurator>
              </DataProvider>
            </CloudWatchProvider>
          } />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
